import React from 'react';
import Helmet from 'react-helmet';

import { Container, Row, Col, Button } from 'reactstrap';
import logo from "../../assets/images/Logo-Subway.png";
import { Heading1, Paragraph } from '../../components/text';
import '../../styles/index.css';


function Success() {
    return (
        <>
        <Helmet
                title="Subway T&amp;T - Success!"
                meta={[
                { name: 'description', content: 'Tell us what you think! We want to hear from you' },
                { name: 'keywords', content: 'Subway, Trinidad, Contact Subway, Tobago, Trinidad Subway Phone number' },
                ]}
            />
            <main className="flex-grow-1 d-flex align-items-center">
            <Container>
                <Row>
                <Col sm={12} md={4} className="mx-auto text-center">
                    <a href="/"><img src={logo} alt="Subway" /></a>
                    <Container className="bg-white my-6 p-3">
                    <Heading1 sm={2} md={2} className="text-primary m-3">
                        Success!
                    </Heading1>
                    <Paragraph className="text-primary m-3">Your application has been submitted.</Paragraph>
                    <Paragraph className="text-primary m-3">Qualifying applicants will be contacted directly once a suitable position is available.</Paragraph>
                    <Button
                        block
                        color="success"
                        href="/"
                        className="rounded-pill text-white font-weight-bold"
                    >Go back Home</Button>
                    </Container>
                </Col>
                </Row>
            </Container>
            </main>
        </>
    );
}

Success.propTypes = {};

Success.defaultProps = {};

export default Success;